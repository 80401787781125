/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Copenhague"), React.createElement(_components.p, null, "Pour se loger, Copenhague s’articule en six quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Le centre, ", React.createElement("a", {
    href: "https://www.booking.com/district/dk/copenhagen/copenhagen-centre.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "København K"), " : port et rues piétonnes très touristiques."), "\n", React.createElement(_components.li, null, "A l’est, ", React.createElement("a", {
    href: "https://www.booking.com/district/dk/copenhagen/christianshavn.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Christianhavn"), " et la commune libre de Christiania."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/dk/copenhagen/vesterbro.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vesterbro"), ", au sud-ouest : nombreuses boutiques et cafés branchés."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/dk/copenhagen/norrebro.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Nørrebro"), ", plus au nord : un quartier jeune et multi-ethnique."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/dk/copenhagen/indre-osterbro.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Østerbro"), ", au nord de Nørrebro : chic et bourgeois."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/dk/copenhagen/frederiksberg.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Frederiksberg"), ", à l’ouest : quartier calme et résidentiel."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
